module.exports = [{
      plugin: require('/home/travis/build/byronmejia/byronmejia.github.io/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/byronmejia/byronmejia.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/byronmejia/byronmejia.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137419172-1","head":true,"anonymize":true,"respectDNT":true},
    }]
